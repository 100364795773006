import React, { Component, useEffect, useRef } from "react";
import BackgroundImage from 'gatsby-background-image'
import { gsap } from 'gsap'
import utils from "./utils";

export default function PageHeader({ image, title }) {

  const titleEl = useRef()

  useEffect(() => {
    setTimeout(() => {
      // Load in if takes more than 2 seconds
      onLoad()
    }, 2000)
  }, [])

  const onLoad = () => {
    gsap.to('body', {
      opacity: 1,
      duration: 0.5,
      ease: "none",
      onComplete() {
        gsap.to('.page-header', {
          opacity: 1,
          ease: "power1.out",
          delay: 0.25,
          duration: 0.8,
          onComplete() {
            gsap.to([titleEl.current, '#nav'], {
              opacity: 1,
              duration: 0.5,
              ease: "power2.out"
            })
            // gsap.to('.services-header h1', {
            //   opacity: 1,
            //   duration: 0.5,
            //   ease: "power2.out"
            // })
            window.locomotiveScroll.start()
          }
        })
      }
    })
  }

  return (
    <div className="page-header"
      style={{
        opacity: 0
      }}
    >
      <BackgroundImage backgroundColor={utils.getPurpleHex()} fluid={image} className={`h-screen flex items-center justify-center`} onLoad={() => {
        onLoad()
      }}>
        <div className="container px-0 lg:px-8 p-12">
          <h1 ref={titleEl} style={{
            opacity: 0
          }} className="top-page-load-in font-sc capitalize text-xl lg:text-xxl text-sand capitalize">{title}</h1>
        </div>
      </BackgroundImage>
    </div>
  )
}
