import React from "react";
import CircleLink from "./circle-link";
import utils from "../components/utils";
import { window } from "browser-monads";
import WebImage from "./image";

export default function ArticleFeature({ title, firstName, lastName, slug, index, side, image, dropImageMobile }) {
  const desktopAnimation = (index) => {
    window.locomotiveScroll.stop()
    window.locomotiveScroll.scrollTo(`#post-${index}`, {
      duration: 100
    })
  }

  return (
    <section id={`post-${index}`} className="blog-post lg:min-h-screen lg:flex" >
      <div className="blog-post__details flex flex-0 justify-end flex-col px-0 lg:px-12 p-12 relative w-full lg:w-1/2">
        <div className="max-w-lg lg:absolute lg:top-1/2 transform lg:-translate-y-1/2 lg:h-auto py-20 lg:py-0 px-4 lg:px-0">
          <h3 className="font-sailec text-lg mb-8">{title}</h3>
          <p className="font-sailec">Published by {firstName} {lastName}</p>
        </div>
        <CircleLink cssClass="mx-4 lg:mx-0" title="Read Article" isLink={true} to={`/article/${slug}`} duration={utils.getScrollToDuration()} side={side} onPress={() => {
          utils.isDesktop() ? desktopAnimation(index) : utils.fadeOutFromPurple()
        }} />
      </div>
      <WebImage backgroundColor={utils.getSandHex()} image={image} outerCssClass={`blog-post__image flex-0 h-50vh h-mobile-landscape-150vh lg:min-h-screen w-full lg:w-1/2 ${dropImageMobile ? 'hidden lg:block' : ''}`} innerCssClass="w-full h-full" />
    </section >
  )
}
