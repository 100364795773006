import React, { useEffect } from "react";
import Layout from '../components/layout'
import { graphql } from "gatsby"
import ContactForm from '../components/contact-form'
import PageHeader from "../components/page-header";
import ArticleFeature from "../components/article-feature";
import SEO from "../components/seo";
import utils from "../components/utils";

export default function ResourcesPage({ data }) {
  useEffect(() => {
    utils.resetPage()
  }, [])

  const blogPosts = data.allContentfulBlogPost.nodes
  const page = data.contentfulPageNews

  return (
    <Layout>
      <SEO
        title={page.meta.title}
        description={page.meta.description}
        image={page.meta.image.fluid.src}
      />
      <PageHeader image={page.headerImage.fluid} title={page.title} />
      <div className="blog-posts">
        {
          blogPosts.map((post, index) => <ArticleFeature title={post.title} firstName={post.author.firstName} lastName={post.author.lastName} slug={post.slug} index={index} side={index % 2 == 0 ? 'right' : 'left'} image={post.sideImage.fluid} />)
        }
      </div>
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulPageNews {
      title
      headerImage {
        fluid(quality: 95, maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      meta {
        title
        description
        image {
          fluid {
            src
          }
        }
      }
    }
    allContentfulBlogPost(sort: {fields: createdAt, order: DESC}) {
      nodes {
        id
        slug
        title
        content {
          raw
        }
        sideImage { 
          fluid(quality: 90, maxHeight: 1080) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        author {
          firstName
          lastName
        }
      }
    }
  }
`
